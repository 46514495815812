body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  --custom-yellow: #FFC72C;
  --custom-blue: #279AF1;
  --custom-pink: #F26CA7;
  --custom-red: #f15959;
  --custom-gray: #605856;
  /* black and white */
}